export const ADD_TICKET_REQUEST = "ADD_TICKET_REQUEST";
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS";
export const ADD_TICKET_FAIL = "ADD_TICKET_FAIL";
export const ADD_TICKET_RESET = "ADD_TICKET_RESET";

export const GET_TICKET_REQUEST = "GET_TICKET_REQUEST";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAIL = "GET_TICKET_FAIL";

export const GET_SINGLE_TICKET_REQUEST = "GET_SINGLE_TICKET_REQUEST";
export const GET_SINGLE_TICKET_SUCCESS = "GET_SINGLE_TICKET_SUCCESS";
export const GET_SINGLE_TICKET_FAIL = "GET_SINGLE_TICKET_FAIL";

export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL";
export const DELETE_TICKET_RESET = "DELETE_TICKET_RESET";

export const UPDATE_TICKET_REQUEST = "UPDATE_TICKET_REQUEST";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL";
export const UPDATE_TICKET_RESET = "UPDATE_TICKET_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
