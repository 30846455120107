export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL";
export const ADD_TEAM_RESET = "ADD_TEAM_RESET";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";

export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";
export const DELETE_TEAM_RESET = "DELETE_TEAM_RESET";

export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";
export const UPDATE_TEAM_RESET = "UPDATE_TEAM_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
