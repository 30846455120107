export const ADD_MANAGER_REQUEST = "ADD_MANAGER_REQUEST";
export const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS";
export const ADD_MANAGER_FAIL = "ADD_MANAGER_FAIL";
export const ADD_MANAGER_RESET = "ADD_MANAGER_RESET";

export const GET_MANAGER_REQUEST = "GET_MANAGER_REQUEST";
export const GET_MANAGER_SUCCESS = "GET_MANAGER_SUCCESS";
export const GET_MANAGER_FAIL = "GET_MANAGER_FAIL";

export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const DELETE_MANAGER_FAIL = "DELETE_MANAGER_FAIL";
export const DELETE_MANAGER_RESET = "DELETE_MANAGER_RESET";

export const UPDATE_MANAGER_REQUEST = "UPDATE_MANAGER_REQUEST";
export const UPDATE_MANAGER_SUCCESS = "UPDATE_MANAGER_SUCCESS";
export const UPDATE_MANAGER_FAIL = "UPDATE_MANAGER_FAIL";
export const UPDATE_MANAGER_RESET = "UPDATE_MANAGER_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
